"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("/src/stylus/style.styl");
const inviewObserver_1 = require("./lib/inviewObserver");
/*
import "simplebar";
import "simplebar/dist/simplebar.css";
import ResizeObserver from "resize-observer-polyfill";
window.ResizeObserver = ResizeObserver; */
require("./lib/commonAnime");
require("./lib/Slider");
require("./lib/Mypage");
require("./lib/Mypage");
require("./lib/Modal");
require("./lib/Login");
class App {
    constructor() {
        (0, inviewObserver_1.initializeObserver)();
    }
}
new App();
